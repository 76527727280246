/** @jsx jsx */
import { jsx, css } from "@emotion/core";

import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import shevy from "../styleguide/typography";

import DefaultLayout from "../layouts/default";
import SEO from "../components/seo";
import Wrapper from "../components/wrapper";
import Article from "../components/article";

const Page = ({ data, path }) => {
    const thumbnail = data.mdx.frontmatter.thumbnail?.childImageSharp.fluid.src;

    return (
        <DefaultLayout>
            <SEO
                title={data.mdx.frontmatter.title}
                thumbnailPath={thumbnail}
                path={path}
            />
            <Wrapper
                css={css`
                    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.06);
                    display: flex;
                    flex-direction: column;
                `}
            >
                <div
                    css={css`
                        background: rgba(255, 255, 255, 0.6);

                        @media (max-width: 550px) {
                            padding: ${shevy.baseSpacing(5)}
                                ${shevy.baseSpacing(1)} ${shevy.baseSpacing(2)};
                        }

                        @media (min-width: 550px) {
                            padding: ${shevy.baseSpacing(5)}
                                ${shevy.baseSpacing(2)} ${shevy.baseSpacing(2)};
                        }
                    `}
                >
                    <Article title={data.mdx.frontmatter.title}>
                        <MDXRenderer>{data.mdx.body}</MDXRenderer>
                    </Article>
                </div>
            </Wrapper>
        </DefaultLayout>
    );
};

export const pageQuery = graphql`
    query PageQuery($slug: String!) {
        mdx(fields: { slug: { eq: $slug } }) {
            id

            frontmatter {
                title

                rawDate: date
                formattedDate: date(formatString: "MMMM DD, YYYY")

                description
                tags

                thumbnail {
                    childImageSharp {
                        fluid(maxWidth: 800) {
                            src
                        }
                    }
                }
            }

            body
            timeToRead
        }
    }
`;

export default Page;
